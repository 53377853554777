import './App.css';
import {useState} from "react";

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLogin = () => {
        if (['samet','sevval'].includes(username) && password === 'iyikivarsin') {
            setIsLoggedIn(true);
        } else {
            alert('Kullanıcı adı ve parola gereklidir!');
        }
    };

  return (
      <div className="App">
          {!isLoggedIn ? (
              <div className="login-form">
                  <h2>Giriş Yap</h2>
                  <input
                      type="text"
                      placeholder="Kullanıcı Adı"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                  />
                  <br/>
                  <input
                      type="password"
                      placeholder="Parola"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <button onClick={handleLogin}>Giriş Yap</button>
              </div>
          ) : (
              <div className="welcome-container">
                  <h2>Seni çok seviyorum aslan parçası!</h2>
                  <div className="video-container">
                      <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/0RmwVMpxMHQ" // Kendi YouTube video linkinizi buraya ekleyin
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                      ></iframe>
                  </div>
              </div>
          )}
      </div>
  );
}

export default App;
